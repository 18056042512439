// ConfigButton.js
import React, { useState } from 'react';
import { Button, IconButton, useDisclosure } from '@chakra-ui/react';
import ConfigModal from './ConfigModal';

const ConfigButton = ({ handleOpen, handleSubmit, type, variant, buttonText, buttonIcon, modalTitle, modalContent, width, height, color, hoverColor }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const setupData = () => {
        handleOpen();
        onOpen();
    }

    return (
        <>
            {
                type == 'icon-button' ?
                    <IconButton
                        icon={buttonIcon}
                        aria-label={buttonText}
                        onClick={setupData}
                        variant={variant}
                        color={'whitesmoke'}
                        backgroundColor={color}
                        _hover={
                            {
                                backgroundColor: hoverColor
                            }
                        }
                    />
                    :
                    <Button
                        aria-label={buttonText}
                        variant={variant}
                        onClick={setupData}
                        color={variant !== 'outline' ? 'whitesmoke': null}
                        backgroundColor={color}
                        _hover={
                            {
                                backgroundColor: hoverColor
                            }
                        }
                    >
                       {buttonIcon} { buttonIcon ? <p>&nbsp;</p>: null} {buttonText}
                    </Button>
            }
            <ConfigModal
                isOpen={isOpen}
                onClose={onClose}
                title={modalTitle}
                content={modalContent}
                width={width}
                height={height}
            />
        </>
    );
};

export default ConfigButton;
