import React, { useEffect, useLayoutEffect, useState } from 'react'
import SearchBar from '../../../components/utils/SearchBar'
import { Button, Card, Text, IconButton, Box, HStack, Badge } from '@chakra-ui/react'
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react'
// import { myETLs } from '../../../data/my_etls'
import { FiEdit2 } from "react-icons/fi";
import { FaPlus } from 'react-icons/fa'
import ConfigButton from '../../../components/utils/ConfigButton'
import ETLSetup from './ETLSetup'
import { useEditContext } from '../../../contexts/ETLEditContext'
import axios from 'axios';
import { environment } from '../../../environments/environment';
import { useAuth } from '../../../contexts/AuthContext';

const MyETLs = () => {

    const { setCurrentEditData, editCount } = useEditContext();
    const { currentUser } = useAuth()
    const [myETLData, setMyETLData] = useState({data: []})

    const emptyETL = {
        name: '',
        source: 'Airtable',  // Default to Airtable
        destination: '',
        frequency: '',
        status: 'inactive',
        notify: false,
        customApi: '',
        useremail: currentUser?.email
    }

    const setupData = (selectedETL) => {
        console.log('etl is: ', selectedETL);
        setCurrentEditData(selectedETL);
    }

    const getMyETLData = async () => {
        console.log('enene')
        try {
            const response = await axios.get(environment.serverUrl + '/firestore/getMyJobs?account_token=' + currentUser?.token)

            console.log('response: ', response)

            if (response.data) {
                setMyETLData(response);
            }
        } catch (error) {
            console.error("Error verifying token with backend:", error);
            window.alert(error?.response?.data)

            return undefined;
        }
    };


    useEffect(() => {
        console.log('current count: ', editCount)
        getMyETLData();
    }, [editCount])

    useLayoutEffect(()=>{
        getMyETLData();
    }, [])
    
    return (
        <div className='dashboard-content'>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <SearchBar />
                <br />
                <Text as='h5' size={'md'}>Extract - Transform - Load</Text>
                <br />
            </div>
            <Box width={'80%'} marginBottom={'100px'}>
                <HStack width={'100%'} display={'flex'} justifyContent={'space-between'} mb={4}>
                    <Text as={'h2'}>My ETLs</Text>
                    <ConfigButton type={'button'} buttonText={'New ETL'} buttonIcon={<FaPlus />} color={'#461931'} hoverColor={'#6D3552'}
                        modalContent={<ETLSetup />}
                        handleOpen={() => setupData(emptyETL)}
                    />
                </HStack>
                <Card width={'100%'} marginBottom={'20px'}>
                    <TableContainer mt='5' mb='5'>
                        <Table variant='simple'>
                            <TableCaption>My ETLs History</TableCaption>
                            <Thead>
                                <Tr>
                                    <Th >NAME</Th>
                                    <Th>DATA SOURCE</Th>
                                    <Th maxWidth={100}>DESTINATION</Th>
                                    <Th>FREQUENCY</Th>
                                    <Th>LATEST RUN</Th>
                                    <Th>NOTIFICATION</Th>
                                    <Th>STATUS</Th>
                                    <Th>DETAILS</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {
                                    myETLData.data.map((etl, index) => {
                                        return (
                                            <Tr key={index} id={etl.id}>
                                                <Td maxWidth="150px" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">{etl.name}</Td>
                                                <Td maxWidth="150px" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">{etl.source}</Td>
                                                <Td maxWidth="150px" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">{etl.destination}</Td>
                                                <Td maxWidth="100px" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">{etl.frequency}</Td>
                                                <Td maxWidth="150px" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">{etl.latestrun}</Td>
                                                <Td maxWidth="100px" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">{`${etl.notify}`}</Td>
                                                <Td maxWidth="100px">
                                                    <Badge colorScheme={etl.status.toLowerCase() === 'active' ? 'green' : 'gray'}>
                                                        {etl.status}
                                                    </Badge>
                                                </Td>
                                                <Td>
                                                    <ConfigButton
                                                        type={'button'}
                                                        variant='outline'
                                                        buttonText={'Details'}
                                                        buttonIcon={<FiEdit2 />}
                                                        hoverColor={'#D269A0'}
                                                        modalContent={<ETLSetup />}
                                                        handleOpen={() => setupData(etl)}
                                                    />
                                                </Td>
                                            </Tr>
                                        );
                                    })
                                    
                                }
                            </Tbody>
                            <Tfoot>
                                <Tr>
                                    <Th>NAME</Th>
                                    <Th>DATA SOURCE</Th>
                                    <Th>DESTINATION</Th>
                                    <Th>FREQUENCY</Th>
                                    <Th>LATEST RUN</Th>
                                    <Th>STATUS</Th>
                                    <Th>DETAILS</Th>
                                </Tr>
                            </Tfoot>
                        </Table>
                    </TableContainer>
                </Card>
            </Box>
        </div>
    )
}

export default MyETLs