import React, { useEffect, useLayoutEffect, useState } from 'react'
import { ChakraProvider, VStack, Text, Heading, Box } from '@chakra-ui/react';
import WithSubnavigation from "../../components/utils/Navbar";
import Footer from "../../components/utils/Footer";
import SimpleSidebar from '../../components/utils/Sidebar';
import './Dashboard.css'
import { theme } from '../../theme/ThemeUtils';
import MyETLs from './content/MyETLs';
import { ETLEditProvider } from '../../contexts/ETLEditContext';

const DashboardPage = () => {
  const [pageContent, setPageContent] = useState(<div><h1>Test</h1></div>);

  const updateContentBasedOnHash = () => {
    const hash = window.location.hash;
    console.log('hash is: ', hash)

    let overviewContainer = document.getElementById('overview-container');
    let myETLsContainer = document.getElementById('my-etls-container');
    let oContainer = document.getElementById('other-container');
    if (hash.includes('overview-markets') || hash == '') {
      overviewContainer.style.display = 'initial';
      myETLsContainer.style.display = 'none'
      oContainer.style.display = 'none';
    } else if (hash.includes('my-etls')) {
      overviewContainer.style.display = 'none';
      myETLsContainer.style.display = 'initial'
      oContainer.style.display = 'none';
    } else {
      overviewContainer.style.display = 'none';
      myETLsContainer.style.display = 'none'
      oContainer.style.display = 'initial';
    }
  };

  useEffect(() => {
    updateContentBasedOnHash();

    window.addEventListener('hashchange', updateContentBasedOnHash);

    return () => {
      window.removeEventListener('hashchange', updateContentBasedOnHash);
    };
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <div className='app-dashboard'>
        <WithSubnavigation></WithSubnavigation>
        <Box className='dashboard-container'>
          <SimpleSidebar content={
            <div>
              <div id='overview-container'>
                <h1>Under Construction</h1>
              </div>
              <div id='my-etls-container'>
                <ETLEditProvider><MyETLs /></ETLEditProvider>
              </div>
              <div id='other-container'>
                <h1>Under Construction</h1>
              </div>
            </div>
          } />
        </Box>
        <Footer />
      </div>
    </ChakraProvider>
  )
}

export default DashboardPage