import React, { Component } from 'react';
import HomePage from './pages/home/HomePage';
import { AuthProvider } from './contexts/AuthContext';

class App extends Component {

  render() {
    return (
      <AuthProvider><HomePage/></AuthProvider>
    );
  }
}

export default App;
