// src/context/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { environment } from '../environments/environment';
import { app } from '../firebase';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);

    let navigate = useNavigate();

    // Sign in with Google
    const signInWithGoogle = async () => {
        try {

            const auth = getAuth(app);
            const provider = new GoogleAuthProvider();

            signInWithPopup(auth, provider)
                .then(async (result) => {
                    const credential = GoogleAuthProvider.credentialFromResult(result);
                    const user = result.user;
                    const token = user.accessToken;
                    console.log('token: ', token)
                    console.log(user)
                    let data = await verifyTokenWithBackend(token);

                    console.log('data: ', data)

                    if (data) {
                        console.log('successful: ', data)
                        setCurrentUser(user);
                        navigate('/home', {replace: true})
                    } else {
                        setCurrentUser(null);
                    }
                }).catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    const customData = error.customData;
                    // const email = error.customData.email;
                    const credential = GoogleAuthProvider.credentialFromError(error);

                    console.log({
                        errorCode,
                        errorMessage,
                        customData,
                        credential
                    })
                });

        } catch (error) {
            console.error("Error signing in with Google:", error);
            throw error;
        }
    };

    // Verify the token with your NestJS backend
    const verifyTokenWithBackend = async (token) => {
        // if(currentUser?.token) return
        console.log('enene')
        try {
            const response = await axios.post(environment.serverUrl + '/authentication/google', {
                "token": token
            }, {
                headers: { 'Content-Type': 'application/json' },
            })

            console.log('response: ', response)

            if (!response.data?.message?.includes('authenticated')) {
                throw new Error('Unauthorized or invalid token');
            }
            return response.data;
        } catch (error) {
            console.error("Error verifying token with backend:", error);
            window.alert(error?.response?.data)

            return undefined;
        }
    };

    // Sign out
    const signOut = async () => {
        const auth = getAuth(app);
        await auth.signOut();
        setCurrentUser(null);
    };

    // Check if user is already authenticated when the app loads
    useEffect(() => {
        const auth = getAuth(app);
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                const token = await user.getIdToken();
                const verifiedUser = await verifyTokenWithBackend(token);
                // console.log('verifiedUser: ', {...verifiedUser, 'token': token})
                setCurrentUser({...verifiedUser, 'token': token});
            } else {
                setCurrentUser(null);
            }
            setLoading(false);
        });

        return unsubscribe;
    }, []);

    const value = {
        currentUser,
        loading,
        signInWithGoogle,
        signOut,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
