import { Input, Text } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';
import { environment } from '../../../environments/environment';

const AirtableBuilder = ({ etlData, handleChange, mode }) => {
    const apiTokenRef = useRef(null);
    const baseIdRef = useRef(null);
    const tableIdOrNameRef = useRef(null);

    const baseUrl = mode === 'source' 
        ? environment.serverUrl + '/airtable-connector/listRecords/' 
        : environment.serverUrl + '/airtable-connector/createRecords/'; // Example base URL for destination

    // Helper function to parse the URL and extract relevant parameters
    const parseUrl = (url) => {
        const urlParams = new URLSearchParams(url.split('?')[1]);
        const apiToken = urlParams.get('api_token') || '';
        const parts = url.split('/');

        return {
            apiToken,
            baseId: parts[6] || '', // Adjust index based on your URL structure
            tableIdOrName: parts[7]?.split('?')[0] || '' // Adjust index based on your URL structure
        };
    };

    useEffect(() => {
        const url = etlData[mode] || '';
        const { apiToken, baseId, tableIdOrName } = parseUrl(url);
        
        // Set input values directly through refs
        if (apiTokenRef.current) apiTokenRef.current.value = apiToken;
        if (baseIdRef.current) baseIdRef.current.value = baseId;
        if (tableIdOrNameRef.current) tableIdOrNameRef.current.value = tableIdOrName;
    }, [etlData, mode]);

    const handleInputChange = () => {
        const apiToken = apiTokenRef.current.value;
        const baseId = baseIdRef.current.value;
        const tableIdOrName = tableIdOrNameRef.current.value;

        // Construct the updated URL based on the current inputs
        const newUrl = `${baseUrl}${baseId}/${tableIdOrName}?api_token=${apiToken}`;
        console.log('new url: ', newUrl);

        // Update the relevant field in etlData
        handleChange({
            target: { 
                name: mode, 
                value: newUrl
            }
        });
    };

    return (
        <>
            <Text>Airtable {mode.charAt(0).toUpperCase() + mode.slice(1)} Builder</Text>
            <Input
                ref={apiTokenRef}
                onChange={handleInputChange}
                placeholder="Enter Airtable API Token"
            />
            <Input
                ref={baseIdRef}
                onChange={handleInputChange}
                placeholder="Enter Airtable Base ID"
            />
            <Input
                ref={tableIdOrNameRef}
                onChange={handleInputChange}
                placeholder="Enter Airtable Table ID or Name"
            />
        </>
    );
};

export default AirtableBuilder;
