import {
    Box,
    Button,
    Card,
    HStack,
    Text,
    Input,
    Select,
    Switch,
    FormControl,
    FormLabel,
    Textarea,
    IconButton,
  } from '@chakra-ui/react';
  import React, { useEffect, useRef } from 'react';
  import { useEditContext } from '../../../contexts/ETLEditContext';
  import { FiTool } from 'react-icons/fi';
  import { IoMdLink } from 'react-icons/io';
  import { FaChevronRight, FaTimes } from 'react-icons/fa';
  import AirtableBuilder from './AirtableBuilder';
  
  const ETLSetup = () => {
    const { currentEditData, setCurrentEditData } = useEditContext();
    const nameRef = useRef(null);
    const frequencyRef = useRef(null);
    const statusRef = useRef(null);
    const notifyRef = useRef(null);
  
    useEffect(() => {
      if (currentEditData) {
        if (nameRef.current) nameRef.current.value = currentEditData.name;
        if (frequencyRef.current) frequencyRef.current.value = currentEditData.frequency || '';
        if (statusRef.current) statusRef.current.value = currentEditData.status || '';
        if (notifyRef.current) notifyRef.current.checked = currentEditData.notify || false;
      }
    }, [currentEditData]);
  
    const handleNameChange = (e) => {
      const { value } = e.target;
      setCurrentEditData((prev) => ({ ...prev, name: value }));
    };
  
    const handleSourceChange = (e) => {
      const { value } = e.target;
      setCurrentEditData((prev) => ({ ...prev, source: value }));
    };
  
    const handleDestinationChange = (e) => {
      const { value } = e.target;
      setCurrentEditData((prev) => ({ ...prev, destination: value }));
    };
  
    const handleSwitchChange = (e) => {
      setCurrentEditData((prev) => ({ ...prev, notify: e.target.checked }));
    };
  
    const handleRemoveDestination = () => {
      setCurrentEditData((prev) => ({ ...prev, destination: null }));
    };
  
    const handleAddDestination = () => {
      setCurrentEditData((prev) => ({ ...prev, destination: 'Airtable' }));
    };
  
    return (
      <Box className="setup-container">
        <Text>{currentEditData ? 'Edit Automation' : 'New Automation'}</Text>
        <HStack width={'inherit'} justifyContent={'flex-end'}>
          <Button
            mt={5}
            variant="solid"
            color={'whitesmoke'}
            backgroundColor={'#2B6CB0'}
            _hover={{ backgroundColor: '#568FCA' }}
            onClick={() => console.log('currentEditData: ', currentEditData)}
            aria-label="open menu"
            leftIcon={<FiTool />}
          >
            Run a test
          </Button>
        </HStack>
  
        <FormControl mt={5}>
          <FormLabel>Name</FormLabel>
          <Input
            placeholder="Automation name"
            name="name"
            ref={nameRef}
            onChange={handleNameChange}
          />
        </FormControl>
  
        <HStack mt={5} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
          <Card className="module-section">
            <Text fontWeight={'bold'}>Source</Text>
  
            <Select
              name="source"
              onChange={handleSourceChange}
              value={currentEditData.source || ''}
            >
              <option value="Airtable">Airtable</option>
              <option value="Custom API">Custom API</option>
            </Select>
  
            {currentEditData.source && currentEditData.source.toLowerCase().includes('airtable') ? (
              <AirtableBuilder mode={'source'} etlData={currentEditData} handleChange={handleSourceChange} />
            ) : (
              <Textarea
                name="source"
                placeholder="Enter custom API endpoint"
                onChange={handleSourceChange}
                value={currentEditData.source || ''}
              />
            )}
          </Card>
  
          {currentEditData.destination && (
            <HStack>
              <IoMdLink color="blue" />
              <IoMdLink color="blue" />
              <FaChevronRight color="blue" />
            </HStack>
          )}
  
          {currentEditData.destination ? (
            <Card className="module-section" width={'48%'}>
              <HStack justifyContent={'space-between'}>
                <Text fontWeight={'bold'}>Destination</Text>
                <IconButton
                  icon={<FaTimes />}
                  size="sm"
                  aria-label="Remove Destination"
                  onClick={handleRemoveDestination}
                />
              </HStack>
              <Select name="destination" onChange={handleDestinationChange} value={currentEditData.destination || ''}>
                <option value="Airtable">Airtable</option>
                <option value="Custom API">Custom API</option>
              </Select>
  
              {currentEditData.destination && currentEditData.destination.toLowerCase().includes('airtable') ? (
                <AirtableBuilder mode={'destination'} etlData={currentEditData} handleChange={handleDestinationChange} />
              ) : (
                <Textarea
                  name="destination"
                  placeholder="Enter custom API endpoint"
                  onChange={handleDestinationChange}
                  value={currentEditData.destination || ''}
                />
              )}
            </Card>
          ) : (
            <Button
              mt={3}
              onClick={handleAddDestination}
              leftIcon={<IoMdLink color="blue" />}
            >
              Add Destination
            </Button>
          )}
        </HStack>
  
        <FormControl mt={5}>
          <FormLabel>Frequency (Cron)</FormLabel>
          <Input
            placeholder="* * * * * *"
            name="frequency"
            ref={frequencyRef}
            onChange={(e) => {
              const { value } = e.target;
              setCurrentEditData((prev) => ({ ...prev, frequency: value }));
            }}
          />
        </FormControl>
  
        <FormControl mt={5}>
          <FormLabel>Status</FormLabel>
          <Select name="status" ref={statusRef} onChange={(e) => {
            const { value } = e.target;
            setCurrentEditData((prev) => ({ ...prev, status: value }));
          }}>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </Select>
        </FormControl>
  
        <FormControl display="flex" alignItems="center" mt={5}>
          <FormLabel htmlFor="notify" mb="0">
            Notify?
          </FormLabel>
          <Switch
            id="notify"
            ref={notifyRef}
            isChecked={currentEditData.notify}
            onChange={handleSwitchChange}
          />
        </FormControl>
      </Box>
    );
  };
  
  export default ETLSetup;
  