export let myETLs = {
    data: [
        {
            id: '1',
            name: 'test 1',
            data_source: 'Airtable',
            destination: 'Google Sheets',
            frequency: 'Every Month',
            latest_run: 'Success',
            status: 'Active'
        },
        {
            id: '2',
            name: 'test 2',
            data_source: 'Google Sheets',
            destination: 'Google Sheets',
            frequency: 'Every Month',
            latest_run: 'Success',
            status: 'Active'
        },
        {
            id: '3',
            name: 'test 3',
            data_source: '12 Data',
            destination: 'AWS S3',
            frequency: 'Every Month',
            latest_run: 'Success',
            status: 'Active'
        },
        {
            id: '4',
            name: 'test 4',
            data_source: 'Airtable',
            destination: 'AWS S3',
            frequency: 'Every Month',
            latest_run: 'Success',
            status: 'Inactive'
        },
        {
            id: '3',
            name: 'test 3',
            data_source: '12 Data',
            destination: 'AWS S3',
            frequency: 'Every Month',
            latest_run: 'Success',
            status: 'Active'
        },
        {
            id: '4',
            name: 'test 4',
            data_source: 'Airtable',
            destination: 'AWS S3',
            frequency: 'Every Month',
            latest_run: 'Success',
            status: 'Inactive'
        },
        {
            id: '3',
            name: 'test 3',
            data_source: '12 Data',
            destination: 'AWS S3',
            frequency: 'Every Month',
            latest_run: 'Success',
            status: 'Active'
        },
        {
            id: '4',
            name: 'test 4',
            data_source: 'Airtable',
            destination: 'AWS S3',
            frequency: 'Every Month',
            latest_run: 'Success',
            status: 'Active'
        }
    ]
}