import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button } from '@chakra-ui/react';
import { useEditContext } from '../../contexts/ETLEditContext';

const ConfigModal = ({ isOpen, onClose, title, content, width = 'auto', height = 'auto' }) => {

  const { saveEdit } = useEditContext();

  const handleSubmit = () => {
    saveEdit();
    onClose();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        style={{
          width: width,
          height: height,
          maxWidth: 'none', // To avoid Chakra's default maxWidth
          maxHeight: 'none' // To avoid Chakra's default maxHeight
        }}
      >
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {content}
        </ModalBody>
        <ModalFooter gap={5}>
        <Button
            mt={5}
            variant="outline"
            onClick={onClose}
            aria-label="Cancel"
            _hover={
              {
                color: 'whitesmoke',
                backgroundColor: '#D269A0'
              }
            }
          >
            Cancel
          </Button>
          <Button
            mt={5}
            variant="solid"
            color={'whitesmoke'}
            backgroundColor={"#461931"}
            onClick={handleSubmit}
            aria-label="Save"
            _hover={
              {
                backgroundColor: '#6D3552'
              }
            }
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfigModal;
