import React, { useEffect, useState, useRef } from 'react';
import { Input, Container, Stack, Card, Text } from '@chakra-ui/react';
import { InputGroup, InputLeftElement } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons'; // Chakra's built-in search icon
import { myETLs } from '../../data/my_etls.js';

const SearchBar = () => {
  const [results, setResults] = useState(myETLs.data); // Initialize results with the loaded JSON data
  const [searching, setSearching] = useState(false);
  const containerRef = useRef(null);
  const inputRef = useRef(null);
  const [inputHeight, setInputHeight] = useState(0);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setSearching(false);
      }
    };

    if (inputRef.current) {
      const elementHeight = inputRef.current.clientHeight;
      setInputHeight(elementHeight);
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const searchChannel = (query) => {
    if (!query) {
      // Reset if query is empty
      setResults(myETLs.data); // Use the original data from JSON
      return;
    }

    const filteredResults = myETLs.data.filter(etl =>
      etl.name.toLowerCase().includes(query.toLowerCase()) ||
      etl.data_source.toLowerCase().includes(query.toLowerCase()) ||
      etl.destination.toLowerCase().includes(query.toLowerCase())
    ).slice(0, 10); // Limit to max 10 results

    setResults(filteredResults);
  };

  const isSearching = () => {
    setSearching(true);
  };

  return (
    <Container mt={10} ref={containerRef} centerContent position="relative">
      <InputGroup minWidth={400} >
        <InputLeftElement pointerEvents='none'>
          <SearchIcon color='gray.300' />
        </InputLeftElement>
        <Input
          onFocus={isSearching}
          onChange={($event) => searchChannel($event.target.value)}
          placeholder='Search by ETL name, data source or destination'
          size='lg'
          borderRadius={0}
          border={'solid 1px'}
          borderColor={'gray'}
        />
      </InputGroup>

      {searching && (
        <Card
          zIndex={9999}
          position="absolute"
          top="100%" // Places it directly under the input
          minWidth={400}
          mt={2} // Adds a small margin under the input
          maxHeight={300} // Limits the height of the results
          overflowY="auto" // Makes the results scrollable
        >
          <Stack spacing={3} padding={3}>
            {results.map((result, index) => (
              <Card key={index} id={result.id} padding={2} width="100%" cursor="pointer" _hover={{ bg: 'gray.100' }}>
                <Text fontWeight="bold">{result.name} - {result.data_source} to {result.destination}</Text>
                <Text fontSize="sm">{result.frequency}</Text>
              </Card>
            ))}
          </Stack>
        </Card>
      )}
    </Container>
  );
};

export default SearchBar;
