import React, { createContext, useContext, useState, useEffect } from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { environment } from '../environments/environment';
import { app } from '../firebase';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { myETLs } from '../data/my_etls';

const ETLEditContext = createContext();

export const useEditContext = () => useContext(ETLEditContext);

export const ETLEditProvider = ({ children }) => {
    const [currentEditData, setCurrentEditData] = useState(null);
    const [editCount, seteditCount] = useState(0);


    const handleSubmit = async () => {
        // Logic to create or modify ETL based on `etlData`
        console.log('ETL Data: ', currentEditData);
        try {
            const response = await axios.post(environment.serverUrl + '/firestore/addJob', {

                name: currentEditData?.name,
                source: currentEditData?.source,
                destination: currentEditData?.destination,
                frequency: currentEditData?.frequency,
                status: currentEditData?.status,
                notify: currentEditData?.notify,
                useremail: currentEditData?.useremail

            }, {
                headers: { 'Content-Type': 'application/json' },
            })

            console.log('response: ', response)


        } catch (error) {
            console.error("Error from with backend:", error);

            return undefined;
        }
    };

    const handleUpdate = async () => {
        // Logic to create or modify ETL based on `etlData`
        console.log('ETL Data: ', currentEditData);
        const { id, ...fields } = currentEditData;
        const payload = {
            id: id,
            fields: {
                ...fields
            }
        };
        console.log('payload: ', payload)
        try {
            const response = await axios.patch(environment.serverUrl + '/firestore/updateJob', {
                ...payload
            }, {
                headers: { 'Content-Type': 'application/json' },
            })

            console.log('response: ', response)


        } catch (error) {
            console.error("Error from with backend:", error);

            return undefined;
        }
    };


    const saveEdit = async () => {
        console.log('entered in context');
        console.log('before mod: ', currentEditData);

        if (currentEditData?.id) {
            handleUpdate();
        } else {
            handleSubmit();
        }

        seteditCount(editCount + 1);
    }


    const value = {
        currentEditData,
        editCount,
        setCurrentEditData,
        saveEdit
    };

    return <ETLEditContext.Provider value={value}>{children}</ETLEditContext.Provider>;
};
